<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn v-if="helperService.userHasPermission('essai_add')" class="action-btn" icon="plus" color="primary"
          text="Créer un essai" :to="{
            name: 'essaiGeneralInformationAdd',
          }" />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <Search v-model="search" returnObject :searchEnpoint="list.api" searchPlaceholder="Rechercher un essai">
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'essai', params: { id: item.id } }">
              {{ item.nom }}
            </router-link>
          </template>
          <template v-if="displaySelectProto">
            <SelectExtended id="protocole" v-model="search.filters.protocole.values" multiple search
              :label="search.filters.protocole.label" optionKey="id" optionValue="titre" apiEndpoint="protocole"
              :apiParams="{
                filters: `statut.uid:in(ENCOURS)${filterSeason}`,
                sort: `titre.ASC`
              }" />

            <SelectExtended id="thematique" v-model="search.filters.protocole__thematique.values" multiple search
              label="Thématiques" optionKey="id" optionValue="designation" apiEndpoint="dictionnaire/thematique" />

            <SelectExtended id="culture" v-model='search.filters.protocole__culture.values' multiple search
              label="Cultures" optionKey="id" optionValue="nom" :apiParams="{ limit: 0, sort: 'nom.ASC' }"
              apiEndpoint="culture" />

            <SelectExtended id="annee" v-model="search.filters.protocole__annee.values" multiple search label="Année"
              optionKey="id" optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                'anneerecolte',
              )}/valeur`" />

            <SelectExtended id="parcelle" v-model="search.filters.parcelle.values" multiple :search="true"
              :label="search.filters.parcelle.label" optionKey="id" optionValue="designation" apiEndpoint="parcelle"
              :apiParams="{
                sort: `designation.ASC`
              }" />

            <SelectExtended id="technicien" v-model="search.filters.technicien.values" multiple :search="true"
              :label="search.filters.technicien.label" optionKey="id" optionValue="nom_complet" :apiParams="{
                sort: `prenom.ASC,nom.ASC`,
                filters: `entite.id:in(${$store.state.auth.user.data.entite.id})`
              }" apiEndpoint="utilisateur" />

            <Radio v-model="search.filters.plate_forme.values" id="plate_forme" :items="[
              { label: 'oui', value: true },
              { label: 'non', value: false },
            ]" :label="search.filters.plate_forme.label" inputStyle="inline" />
          </template>
        </Search>

        <List v-if="filterSeasonObj?.filters" :api="list.api" :header="list.headers" :items="list.col"
          :item-key="list.key" :target="list.target" :defaultFilter="filterSeasonObj">
          <template v-slot:group-actions="{ selectedItems }">
            <Btn v-if="helperService.userHasPermission('essai_remove')" text="Supprimer" icon="trash-alt"
              color="default" @click="openModal('deleteItems', selectedItems)" />
          </template>

          <template v-slot:nom="{ item }">
            <template v-if="!item.protocole?.bpe">
              {{ item.nom }}
            </template>
            <div class="essai-bpe" v-else>
              <div>{{ item.nom }}</div>
              <div class="essai-bpe-icon">
                <SvgIcon name="bpe" size="lg" />
              </div>
            </div>
          </template>

          <template v-slot:statut="{ item }">
            <div v-if="item.protocole?.statut?.uid === 'VALIDE'" class="essai-statut essai-statut--filled">
            </div>
            <div v-else class="essai-statut"></div>
          </template>

          <template v-slot:annee="{ item }">
            {{ item.protocole?.annee_recolte?.valeur }}
          </template>

          <template v-slot:culture="{ item }">
            <template v-if="item.protocole.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item.protocole.cultures" :key="culture.id" :id="culture.uid"
                  :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:thematique="{ item }">
            {{ item.protocole?.thematique?.designation }}
          </template>

          <template v-slot:responsable="{ item }">
            {{ item.technicien.email }}
          </template>

          <template v-slot:status="{ item }">
            {{ getValidite(item.fiches_validation?.at(0)) }}
          </template>

          <template v-slot:actions="{ item }">
            <ToolDropdown>
              <MenuItem v-if="helperService.userHasPermission('essai_show')" :item-data="{
                label: 'Consulter',
                iconName: 'visibility',
                route: {
                  name: 'essai',
                  params: {
                    id: item.id,
                  },
                }
              }" />
              <MenuItem v-if="helperService.userHasPermission('essai_edit,essai_remove', 'OR')" :item-data="{
                label: 'Éditer/supprimer',
                iconName: 'create',
                route: {
                  name: 'essaiGeneralInformationEdit',
                  params: {
                    id: item.id,
                    from: $route.name
                  },
                }
              }" />
              <MenuItem :item-data="{
                label: 'Calendrier',
                iconName: 'calendar',
                route: {
                  name: 'essaiCalendar',
                  params: {
                    id: item.id,
                    from: $route.name
                  },
                }
              }" />
              <MenuItem v-if="helperService.userHasPermission('fichier_add,essaifichier_edit')" :item-data="{
                label: 'Ajouter un fichier',
                iconName: 'plus',
                route: {
                  name: 'essaiFiles',
                  params: {
                    id: item.id
                  }
                }
              }" />
              <MenuItem :item-data="{
                label: 'Documents',
                iconName: 'export',
              }" @click="openModal('downloadFiles', item)" />
              <MenuItem v-if="helperService.userHasPermission('log_list')" :item-data="{
                label: 'Voir les logs',
                route: {
                  name: 'ressourceLogs',
                  params: {
                    ressource: 'essai',
                    rid: 'essai',
                    ressourceid: item.id,
                    from: 'essais',
                    title: `Voir les logs`,
                  },
                },
                iconName: 'clipboard-list',
              }" />
            </ToolDropdown>

          </template>
        </List>
      </Section>
    </div>
  </form>

  <!-- Modals -->
  <!-- Groupe d'actions de suppression -->
  <Modal title="Supprimer des essais&nbsp;?" :active="modal.deleteItems" :data="modalData"
    @modal-close="modal.deleteItems = false">
    <template v-slot:modal-body="{ data }">
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} essais&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>

  <!-- Telecharger un document -->
  <Modal title="Télécharger un document" :active="modal.downloadFiles" :data="modalData" size="lg"
    @modal-close="modal.downloadFiles = false">
    <template v-slot:modal-body="{ data }">
      <div class="grid">
        <Btn text="Calendrier de l'essai" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'calendrier')" />
        <Btn text="Bilan" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'bilan')" />
        <Btn text="Fiche parcellaire" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'parcelle-fiche')" />
        <Btn text="Plan d'accès" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('pdf', 'essai', data.id, 'parcelle')" />
        <Btn text="Plan de l'essai en PDF" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="printPlan(data, 'pdf')" />
        <Btn text="Plan de l'essai en XLS" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="printPlan(data, 'xls')" />
        <Btn text="Modalités" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="fileService.exportFile('xls', 'essai', data.id, 'modalites')" />
        <Btn v-if="printLabels" text="Etiquettes parcelle" color="primary" icon="chevron-big-right" hollow iconAfter
          @click="closeOpenModal('downloadFiles', 'downloadLabels', data)" />
        <Btn v-else text="Etiquettes parcelle" color="primary" icon="chevron-big-right" hollow iconAfter disabled />
      </div>

    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.downloadFiles = false" />
    </template>
  </Modal>

  <Modal title="Télécharger une planche d'étiquettes PARCELLE" noScroll :key="componentKey"
    :active="modal.downloadLabels" :data="modalData" @modal-close="modal.downloadLabels = false" :containForm="true"
    :modalValidationSchema="validationSchema" @modal-form-submitted="downloadLabel($event, modalData)">
    <template v-slot:modal-body>
      <div class="grid">
        <SelectExtended id="modele_etiquette_id" label="Modele d'étiquettes" optionKey="id" optionValue="nom"
          apiEndpoint="etiquette/modele" :apiParams="modele_etiquette.type.parcelle" required />

        <Input type="number" id="tuple" label="Imprimer 1 étiquette par multiple de N étiquettes"
          :options="{ min: 1, step: 1 }" required class="input--half" />

        <SelectExtended v-if="labels.entryOrders" class="margin-bottom-quad" id="niveausaisie_uid"
          label="Chemin d'impression" optionKey="uid" optionValue="designation" :items="labels.entryOrders" />
      </div>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.downloadLabels = false" />
      <Btn text="Imprimer" color="primary" btnType="submit" />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Radio from '@/components/form/Radio.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Input from '@/components/form/Input.vue'

export default {
  name: 'EssaisView',

  components: {
    MiniTag,
    Radio,
    MenuItem,
    ToolDropdown,
    Modal,
    Search,
    SelectExtended,
    Pagination,
    List,
    Btn,
    SvgIcon,
    Section,
    Input,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      modele_etiquette_id: this.yup.object().required().nullable(),
      tuple: this.yup.number().integer().min(1).required()
        .nullable(),
      niveausaisie_uid: this.yup.object().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(
      validationSchema,
    )

    return {
      validationSchema,
      errors,
      isSubmitting,
      modele_etiquette: {
        type: {
          parcelle: {
            filters: 'type.uid:eq(PARCELLE)',
          },
        },
      },
      model_etiquette_id: {},
      filterSeason: '',
      filterSeasonObj: {},
      displayList: false,
      displaySelectProto: false,
      search: {
        attributs: ['nom'],
        filters: {
          // Object
          protocole: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Protocole',
          },
          protocole__culture: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Cultures',
          },
          protocole__thematique: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Thématique',
          },
          protocole__annee: {
            type: 'relation',
            values: [],
            key: 'id',
            label: 'Année',
          },
          parcelle: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Parcelle',
          },
          technicien: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Technicien',
          },
          // Booléan
          plate_forme: {
            type: 'boolean',
            values: [],
            label: 'Plateforme',
          },
        },
      },

      list: {
        api: {
          endpoint: 'essai',
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        target: {
          route: {
            name: 'essai',
            paramsFromRoute: undefined,
            itemParams: { id: 'id' },
          },
        },
        headers: [
          { label: 'Numéro', dataField: 'numero', isLink: true },
          { label: 'Nom', dataField: 'nom', isLink: true },
          { label: 'Année', hidden: 'mobile' },
          { label: 'Culture', hidden: 'mobile' },
          { label: 'Thématique', hidden: 'tablet' },
          { label: 'Responsable', hidden: 'tablet' },
          { label: 'Etat' },
        ],
        col: ['numero', 'nom', 'annee', 'culture', 'thematique', 'responsable', 'status'],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        downloadFiles: false,
        downloadLabels: false,
      },

      labels: {
        entryOrders: null,
        tuple: 1,
        entryOrder: {},
      },

      // modaliteFilterItems: [],
      componentKey: 0,
      printLabels: false,
    }
  },

  watch: {
    search: {
      handler(newValue) {
        this.emitter.emit('open-loader')
        this.displaySelectProto = false
        if (!newValue.filters?.protocole__annee?.values.length > 0) {
          this.filterSeason = ''
          this.displaySelectProto = true
          this.emitter.emit('close-loader')
        } else {
          const years = newValue.filters?.protocole__annee?.values.map(
            (year) => (year.key),
          ).join(',')
          this.filterSeason = `|n|annee.id:in(${years})`
          this.displaySelectProto = true
          this.emitter.emit('close-loader')
        }
      },
      deep: true,
    },
  },
  created() {
    this.emitter.emit('open-loader')
    const filterSeason = this.protocolService.filterByAgriculturalSeason('id', false)
    this.filterSeasonObj = this.protocolService.filterByAgriculturalSeason('id', true, 'protocole.annee')
    if (filterSeason) {
      this.filterSeason = `|n|${filterSeason}`
    }

    this.displaySelectProto = true
    this.search.filters.protocole__annee.values = this.filterSeasonObj.years
  },
  mounted() {
    this.emitter.emit('list-update-active-filters')
    this.getEntryOrders()
  },

  methods: {
    async hasMicroparcelles(essai) {
      const idPlan = essai?.plan?.id
      if (idPlan) {
        const res = await this.fetchService.get(`essai/${essai.id}/plan/${idPlan}/microparcelle?limit=1`)
        const datas = await res.data
        this.printLabels = datas.length > 0
      }
    },
    getEntryOrders() {
      this.fetchService.get('dictionnaire/saisie/ordre').then((response) => {
        const entryOrders = response.data
        this.labels.entryOrders = entryOrders.filter((entry) => entry.uid !== 'MANUEL')
      })
    },
    closeOpenModal(toClose, toOpen, data) {
      this.modal[toClose] = false
      this.modalData = data
      if (toOpen === 'downloadLabels') {
        this.modalData = {
          id: data.id,
          modele_etiquette_id: null,
          niveausaisie_uid: { key: 'ORDREGEOASC', value: 'Ordre géographique croissant' },
          tuple: 1,
        }
      }

      this.modal[toOpen] = true
    },

    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
      this.hasMicroparcelles(data)
    },

    downloadLabel(event, data) {
      const datas = { ...event }
      datas.niveausaisie_uid = datas.niveausaisie_uid?.key
      const mid = datas.modele_etiquette_id?.key
      delete datas.modele_etiquette_id

      const qp = []
      let qs = []
      Object.keys(datas).forEach((key) => {
        if (datas[key] && datas[key] !== undefined) {
          qp[key] = datas[key]
        }
      })

      Object.keys(qp).forEach((key) => {
        qs.push(`${key}=${qp[key]}`)
      })

      qs = `?${qs.join('&')}`

      const endpoint = `taches/type/pdf/ressource/essai/${data.id}/etiquettes/${mid}/parcelles${qs}`
      const filename = `essai_${data.id}_etiquettes_parcelles`
      this.fileService.downloadFile(endpoint, filename, 'pdf')

      this.modal.downloadLabels = false
    },

    getValidite(fichevalidation) {
      let isClosed = false
      if (fichevalidation) {
        isClosed = !!(fichevalidation.abandone_essai || fichevalidation.cloture)
      }

      return isClosed ? 'Clos' : 'En cours'
    },

    printPlan(data, type) {
      let displayOptions = {
        id: null,
        displayGeo: true,
        displayComputedId: true,
        displayRepetition: true,
        displayModalityNumber: true,
        displayDesignation: true,
        displayHeaders: true,
        displayColors: true,
      }

      let off = null

      this.fetchService
        .get(`protocole/${data.protocole.id}/modalite?sort=ordre.ASC`, {
          limit: 0,
        })
        .then((reponseModalite) => {
          const modalities = reponseModalite.data
          const displayModalitiesOptions = modalities.map((t) => t.id)

          const localDisplayModalitiesOptions = JSON.parse(
            localStorage.getItem('displayPlanModalitiesOptions'),
          )
          const f = localDisplayModalitiesOptions.find(
            (element) => element.id === data.plan.id,
          )
          if (f) {
            off = this.getDifference(displayModalitiesOptions, f.modalities)
          }

          if (localStorage.getItem('displayPlanOptions')) {
            const localDisplayPlanOptions = JSON.parse(
              localStorage.getItem('displayPlanOptions'),
            )
            const found = localDisplayPlanOptions.find(
              (element) => element.id === data.plan.id,
            )
            if (found) {
              displayOptions = found
            }

            displayOptions.modalites_off = off
          }

          const printOptions = {
            position_geographique: displayOptions.displayGeo ? 1 : 0,
            modalite_bloc: displayOptions.displayComputedId ? 1 : 0,
            numero_ligne: displayOptions.displayHeaders ? 1 : 0,
            numero_colonne: displayOptions.displayHeaders ? 1 : 0,
            numero_modalite: displayOptions.displayModalityNumber ? 1 : 0,
            numero_bloc: displayOptions.displayRepetition ? 1 : 0,
            libelle_modalite: displayOptions.displayDesignation ? 1 : 0,
            couleurs: displayOptions.displayColors ? 1 : 0,
          }

          if (displayOptions.modalites_off !== null) {
            printOptions.modalites_off = displayOptions.modalites_off
          }

          this.fileService.exportFile(type, 'essai', data.id, 'plan', printOptions)
        })
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`essai/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `L'essai ${item.nom} a été supprimé.`,
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },

    getDifference(array1, array2) {
      return array1.filter((object1) => !array2.some((object2) => object1 === object2))
    },
  },
}
</script>

<style lang="scss" scoped>
.essai-statut {
  height: 15px;
  width: 15px;
  border: 1px solid $color-gray;
  border-radius: $border-radius-base;
  transform: rotate(45deg);

  &--filled {
    border: 1px solid $color-primary;
    background: $color-primary;
  }
}

.input--half {
  ::v-deep(.input) {
    width: 50%;
  }
}
</style>
